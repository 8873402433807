import { createContext, useEffect, useState } from "react";
const AuthContext = createContext({});
import io from "socket.io-client";

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState(
    { token: localStorage.getItem("token") } || {}
  );
  const [isLogin, setIsLogin] = useState(false);
  const [socket, setSocket] = useState();

  useEffect(() => {
    if (auth?.token) {
      localStorage.setItem("token", auth.token);
    }
  }, [auth]);
  useEffect(() => {
    const socketobj = io("http://13.203.86.137:4008");
    // const socketobj = io("http://code.webspeed.online:4008");
    setSocket(socketobj);
    if (auth?.token) {
      socketobj.emit("authenticate", auth?.token);
    }
  }, []);
  useEffect(() => {
    if (!socket) return;
    socket.onAny((event, message) => {
      console.log(event, message);
    });
  }, [socket]);
  return (
    <AuthContext.Provider value={{ auth, setAuth, socket }}>
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
