import { Link, useLocation } from "react-router-dom";
import menu from "./menu";
import adminLogo from "../../assets/img/AdminLTELogo.png";
import userLogo from "../../assets/img/user2-160x160.jpg";
const ChildComponent = ({ child }) => {
  const location = useLocation();
  return (
    <ul className="nav nav-treeview">
      {child.map((item) => (
        <li className="nav-item" key={item.name}>
          <Link
            to={item.path}
            className={`nav-link ${
              item.path == location.pathname ? "active" : null
            }`}
          >
            <i className="far fa-circle nav-icon"></i>
            <p>{item.name}</p>
          </Link>
        </li>
      ))}
    </ul>
  );
};
const Sidebar = () => {
  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <Link to="/admin/users/list" className="brand-link">
        <img
          src="/dist/img/AdminLTELogo.png"
          alt="AdminLTE Logo"
          className="brand-image img-circle elevation-3"
          style={{ opacity: 0.8 }}
        />
        <span className="brand-text font-weight-light">Socket Login</span>
      </Link>

      <div className="sidebar">
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {menu.map((item) => {
              return (
                <li
                  key={item.name}
                  className={
                    item?.children ? "nav-item menu-is-opening menu-open" : ""
                  }
                >
                  <Link
                    to={item.path ? item.path : "#"}
                    className={`nav-link `}
                  >
                    <i className={`nav-icon fas ${item?.icon}`}></i>
                    <p>{`  ${item.name}`}</p>
                  </Link>
                  {item?.children ? (
                    <ChildComponent child={item.children} />
                  ) : null}
                </li>
              );
            })}
          </ul>
        </nav>
      </div>
    </aside>
  );
};
export default Sidebar;
