import Home from "../pages/Home";
import Login from "../pages/Login";

import UsersList from "../pages/UsersList";
import Welcome from "../pages/Welcome";
const path = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
    public: true,
  },
  {
    path: "/admin/users/list",
    component: UsersList,
  },
  {
    path: "/welcome",
    component: Welcome,
    public: true,
  },
];

export default path;
