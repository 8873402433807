import { useState } from "react";
import useAuth from "../hooks/useAuth";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";
const Welcome = () => {
  const location = useLocation();
  //function for calling login api.
  const { auth, setAuth, socket } = useAuth();

  return (
    <>
      {auth?.token ? (
        <Navigate to="/users/list" state={{ from: location }} replace />
      ) : (
        ""
      )}
      {!location?.state?.success ? <Navigate to="/login" /> : ""}
      <div
        style={{
          alignItems: "center",
          backgroundColor: "#e9ecef",
          display: "flexbox",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          justifyContent: "center",
        }}
      >
        Welcome
      </div>
    </>
  );
};
export default Welcome;
