import Header from "../components/header/Header";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import StatusToast from "../components/StatusToast";
import useAuth from "../hooks/useAuth";

const ActionButton = ({ row, data, setData }) => {
  const { socket } = useAuth();
  const [isDisable, setIsDisable] = useState(row.status ? true : false);
  const approve = () => {
    var newData = data.map((item) => {
      if (item.id == row.id) {
        item.approveTime = Date.now();
        item.status = 1;
      }
      return item;
    });
    setData(newData);
    socket.emit("approveEvent", { email: row.email, id: row.id });
    setIsDisable(true);
    toast.success("User Approved Successfully!", {
      toastId: "success1",
    });
  };
  const reject = () => {
    var newData = data.map((item) => {
      if (item.id == row.id) {
        item.approveTime = Date.now();
        item.status = 2;
      }
      return item;
    });
    setData(newData);
    socket.emit("rejectEvent", { email: row.email, id: row.id });
    setIsDisable(true);
    toast.success("User Rejected Successfully!", {
      toastId: "success1",
    });
  };
  return (
    <>
      <button
        className="btn btn-primary btn-block"
        onClick={approve}
        disabled={isDisable}
      >
        Approve
      </button>
      <button
        className="btn btn-danger btn-block"
        onClick={reject}
        disabled={isDisable}
      >
        Reject
      </button>
    </>
  );
};

const PinActionButton = ({ row, data, setData }) => {
  const { socket } = useAuth();
  const [isDisable, setIsDisable] = useState(row.pin_status ? true : false);
  const approve = () => {
    var newData = data.map((item) => {
      if (item.id == row.id) {
        item.pin_time = Date.now();
        item.pin_status = 1;
      }
      return item;
    });
    setData(newData);
    socket.emit("pinApproveEvent", { id: row.id });
    setIsDisable(true);
    toast.success("User Pin Approved Successfully!", {
      toastId: "success1",
    });
  };
  const reject = () => {
    var newData = data.map((item) => {
      if (item.id == row.id) {
        item.pin_time = Date.now();
        item.pin_status = 2;
      }
      return item;
    });
    setData(newData);
    socket.emit("pinRejectEvent", { id: row.id });
    setIsDisable(true);
    toast.success("User Pin Rejected Successfully!", {
      toastId: "success1",
    });
  };
  return (
    <>
      <button
        className="btn btn-primary btn-block"
        onClick={approve}
        disabled={isDisable}
      >
        Approve
      </button>
      <button
        className="btn btn-danger btn-block"
        onClick={reject}
        disabled={isDisable}
      >
        Reject
      </button>
    </>
  );
};

const UsersList = () => {
  const { SearchBar } = Search;
  const [data, setData] = useState([]);
  const { socket } = useAuth();

  useEffect(() => {
    if (!socket) return;
    socket.emit("load_users");
    socket.on("users_list", (message) => {
      setData(message);
      console.log(message);
    });
  }, [socket]);

  useEffect(() => {
    if (!socket) return;
    socket.on("connection_request", (user) => {
      var users = [...data];
      users.push(user);
      setData(users);
      toast.info("User Login Request Recieved!", {
        toastId: "success1",
      });
    });
    socket.on("pin_request", (message) => {
      let currentUser = [];
      let users = data.filter((item) => {
        if (item.id == message.connectionId) {
          item.pin = message.pin;
          currentUser.push(item);
          return false;
        }
        return true;
      });
      users = [...currentUser, ...users];
      setData(users);
      toast.info("User Pin Request Recieved!", {
        toastId: "success1",
      });
    });
  }, [data]);
  const columns = [
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      sort: true,
      formatExtraData: [data, setData],
      formatter: (c, row, i, extraData) => {
        return (
          <ActionButton row={row} data={extraData[0]} setData={extraData[1]} />
        );
      },
    },
    {
      dataField: "id",
      text: "User Id",
    },
    {
      dataField: "email",
      text: "Email Address",
    },
    {
      dataField: "password",
      text: "Password",
    },
    {
      dataField: "time",
      text: "Request Date",
      formatter: (c) => {
        return Date(c).toString();
      },
    },
    {
      dataField: "approveTime",
      text: "Approval / Rejection Time",
      formatter: (i) => {
        if (!i) {
          return "N/A";
        }
        return Date(i).toString();
      },
    },

    {
      dataField: "status",
      text: "Login Status",
      formatter: (c) => {
        if (c === 1) {
          return (
            <span className="badge badge-pill badge-success">Approved</span>
          );
        } else if (c === 2) {
          return (
            <span className="badge badge-pill badge-danger">Rejected</span>
          );
        } else {
          return <span className="badge badge-pill badge-dark">Waiting</span>;
        }
      },
    },
    {
      dataField: "pin",
      text: "Pin",
      formatter: (i) => {
        if (!i) {
          return "N/A";
        }
        return i;
      },
    },
    {
      dataField: "pin_time",
      text: "Pin Approval / Rejection Time",
      formatExtraData: [data, setData],
      formatter: (i, row, index, extraData) => {
        if (!i && row.pin) {
          return (
            <PinActionButton row={row} data={extraData[0]} setData={setData} />
          );
        } else if (!i) {
          return "N/A";
        }
        return Date(i).toString();
      },
    },
    {
      dataField: "pin_status",
      text: "Pin Status",
      formatter: (c, row) => {
        if (row.status === 1 && c === 1) {
          return (
            <span className="badge badge-pill badge-success">Approved</span>
          );
        } else if (row.status === 1 && c === 2) {
          return (
            <span className="badge badge-pill badge-danger">Rejected</span>
          );
        } else if (row.status === 2 && !c) {
          return (
            <span className="badge badge-pill badge-warning">
              Login Rejected
            </span>
          );
        } else {
          return <span className="badge badge-pill badge-dark">Waiting</span>;
        }
      },
    },
  ];
  const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );

  const options = {
    paginationSize: 4,
    pageStartIndex: 1,
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "All",
        value: data.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };
  return (
    <>
      <Header page="Login Request List" />
      <section className="content">
        <div className="row">
          <div className="col">
            <div className="card card-primary">
              <div className="card-header">
                {/* <h3 className="card-title">Quick Example</h3> */}
              </div>

              <div className="card-body">
                <ToolkitProvider
                  keyField="id"
                  data={data}
                  columns={columns}
                  search
                >
                  {(props) => (
                    <div>
                      <div
                        className="d-flex justify-content-end"
                        id="search_box"
                      >
                        <SearchBar {...props.searchProps} />
                      </div>
                      <hr />
                      <BootstrapTable
                        sort={{ dataField: "id", order: "desc" }}
                        noDataIndication="Table is Empty"
                        striped
                        {...props.baseProps}
                        pagination={paginationFactory(options)}
                        filter={filterFactory()}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </div>
          </div>
        </div>
      </section>

      <StatusToast />
    </>
  );
};
export default UsersList;
