import { useState } from "react";
import useAuth from "../hooks/useAuth";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-toastify";
import StatusToast from "../components/StatusToast";
import logo from "../assets/img/Logo.svg";
const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pin, setPin] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [connectionId, setConnectionId] = useState("");
  const [state, setState] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  //function for calling login api.
  const { auth, setAuth, socket } = useAuth();
  const signIn = async () => {
    if (isSubmit) return;
    setIsSubmit(true);
    if (!email || !password) {
      setIsSubmit(false);
      return;
    }
    socket.emit("loginEvent", {
      email: email,
      password: password,
    });
    setIsSubmit(false);
  };
  const onPinSubmit = async () => {
    if (isSubmit) return;
    setIsSubmit(true);
    if (!connectionId || !pin) {
      setIsSubmit(false);
      return;
    }
    socket.emit("pinEvent", {
      connectionId: connectionId,
      pin: pin,
    });
    setState(3);
    setIsSubmit(false);
  };
  useEffect(() => {
    socket?.on("loginResponse", (message) => {
      if (message.code === "ERR101") {
        toast.error("Invalid Email and Password!", {
          toastId: "success1",
        });
      }
      if (message.code === "0") {
        setAuth({ token: message.authToken });
      }
    });
    socket?.on("connection_request", (message) => {});
    socket?.on("wait_for_approval", (message) => {
      setState(1);
    });
    socket?.on("approvalEvent", (message) => {
      if (email === message.email) {
        setState(2);
        setConnectionId(message.connectionId);
      }
    });
    socket?.on("rejectionEvent", (message) => {
      if (email === message.email) {
        setState(0);
        toast.error("Please enter Correct Credentials!", {
          toastId: "success1",
        });
      }
    });
    socket?.on("pinApprovalEvent", (message) => {
      if (connectionId === message.connectionId) {
        navigate("/welcome", { state: { success: true } });
      }
    });
    socket?.on("pinRejectionEvent", (message) => {
      if (connectionId === message.connectionId) {
        setState(0);
        toast.error("Please enter Correct Pin!", {
          toastId: "success1",
        });
        setConnectionId("");
      }
    });
  }, [socket, email, connectionId]);
  return (
    <>
      {auth?.token ? (
        <Navigate to="/admin/users/list" state={{ from: location }} replace />
      ) : (
        ""
      )}
      <div
        style={{
          alignItems: "center",
          backgroundColor: "#ffffff",
          display: "flexbox",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          justifyContent: "center",
        }}
      >
        {state === 0 && (
          <div className="login-box">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={logo} style={{ height: 150, width: 150 }} />
            </div>
            <div className="login-logo">
              <b>Login to Sockets</b>
            </div>
            <div className="card-body login-card-body">
              <p className="login-box-msg">Sign in to start your session</p>

              <form>
                <div className="input-group mb-3">
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope"></span>
                    </div>
                  </div>
                </div>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    className="form-control"
                    placeholder="Password"
                    value={password}
                    autoComplete="true"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock"></span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <button
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        signIn();
                      }}
                      className="btn btn-block"
                      style={{ color: "white", backgroundColor: "orange" }}
                    >
                      Sign In
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        )}
        {state === 1 && (
          <div className="login-logo">Waiting For IT Approval....</div>
        )}

        {state === 2 && (
          <div className="login-box">
            <div className="login-logo">
              <b>Admin</b>LTE
            </div>
            <div className="card">
              <div className="card-body login-card-body">
                <p className="login-box-msg">
                  Enter Pincode To Start Your Session
                </p>

                <form>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Pin"
                      value={pin}
                      onChange={(e) => setPin(e.target.value)}
                    />
                    <div className="input-group-append">
                      <div className="input-group-text">
                        <span className="fas fa-envelope"></span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <button
                        type="button"
                        onClick={(e) => {
                          e.preventDefault();
                          onPinSubmit();
                        }}
                        className="btn btn-primary btn-block"
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        {state === 3 && (
          <div className="login-logo">Waiting For PIN Approval....</div>
        )}
      </div>

      <StatusToast />
    </>
  );
};
export default Login;
